import React, { useState ,useEffect,useMemo} from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AddTransportGroupModel({ isOpen, onClose,submitClick,initialData  }) {
  const [transportCodes, setTransportCodes] = useState(initialData?.transportCodes || []);
  const [nameGroup, setNameGroup] = useState(initialData?.name || '');

  useEffect(() => {
    if (initialData) {
      // If editing, set the modal's state with the initialData
      setTransportCodes(initialData.transportCodes || []);
      setNameGroup(initialData.name || '');
    } else {
      // If adding a new location, clear the modal's state
      setTransportCodes([]);
      setNameGroup('');
    }
  }, [initialData]);

  // To handle changes in "Name Località" and "Regione"
  const handleNameChange = (e) => {
    setNameGroup(e.target.value);
  };

  const handleFieldChange = (id, field, value) => {
    setTransportCodes(transportCodes.map(f => 
      f.id == id ? { ...f, [field]: value } : f
    ));
  };

  const handleAddFields = () => {
    const newField = {
      id:Date.now() + Math.random().toString(36).substring(2, 9),
      code: ''
    };
    setTransportCodes([...transportCodes, newField]);
  };

  const handleDeleteField = (id) => {
    setTransportCodes(transportCodes.filter(field => field.id !== id));
  };

  // Handle the submit action and pass the data up to the parent component
  const handleSubmit = () => {
    const data = {
        name: nameGroup,
        transportCodes
    };
    submitClick(data); // Call the submitClick prop with all the data
    onClose(); // Close the modal
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
          minWidth: 300,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 2,
          }}
        >
          <Typography variant="h6">Aggiungi Gruppo Trasporto</Typography>
          <IconButton onClick={onClose}>
            <Typography>X</Typography>
          </IconButton>
        </Box>

         <TextField
          label="Name Gruppo Transporto"
          variant="outlined"
          fullWidth
          margin="normal"
          value={nameGroup}
          onChange={handleNameChange} 
        />
        
        {transportCodes.map((field, index) => (
          <Box key={`${index + 1}`}>
            <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>{`${index + 1}`}</Typography>
            <TextField
              label="Codice Transporto"
              variant="outlined"
              fullWidth
              value={field.code}
              onChange={(e) => handleFieldChange(field.id, 'code', e.target.value)} // Update dynamic field
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleDeleteField(field.id)}>
                    <DeleteIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
        ))}
        <Button
          sx={{ mt: 2 }}
          variant="outlined"
          fullWidth
          onClick={handleAddFields}
        >
          Collega Nuova
        </Button>

        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleSubmit} // Submit the form on click
        >
          Aggiungi
        </Button>
      </Box>
    </Modal>
  );
}
