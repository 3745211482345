import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import Card from "../../Components/Card/Card";
import article1 from "../../assets/article1.png";
import article2 from "../../assets/article2.png";
import { useNavigate } from "react-router-dom";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Divider,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import EventPackageTable from "../../Components/EventPackageTable/EventPackageTable";
import TouristicDestinationTable from "../../Components/TouristicDestinationTable/TouristicDestinationTable";
import Table from "../../Components/Table/Table";
import IdealVacationsTable from "../../Components/IdealVacationsTable/IdealVacationsTable";
import MiniBannerCard from "../../Components/MiniBannerCard/MiniBannerCard";
import BlogCard from "../../Components/BlogCard/BlogCard";
import useLandingPage from "../../library/hooks/useLandingPage/useLandingPage";
import useIdealVacation from "../../library/hooks/useIdealVacation/useIdealVacation";
import useEventPackage from "library/hooks/useEventPackage";
import useTouristicDestinationPackage from "../../library/hooks/useTouristicDestination/useTouristicDestination";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover": {
      color: "#26529C",
    },
  },
  icon: {
    fill: "#26529C",
  },
  menuItem: {
    color: "#26529C",
    "&:hover": {
      backgroundColor: "#e3e9f2",
    },
  },
}));

export default function HoescapePage() {
  /**
   * Api Context
   */
  const {
    callFetchAllLandingPagesApi,
    callEnableDisableLandingPageByIdApi,
    callDeleteLandingPageByIdApi,
  } = useLandingPage();

  const { callFetchAllIdealVacationsApi, callDeleteIdealVacationByIdApi } =
    useIdealVacation();

  const {
    callFetchAllEventPackagesApi,
    callEnableDisableEventPackageByIdApi,
    callDeleteEventPackageByIdApi,
  } = useEventPackage();


  const {
    callFetchAllTouristicDestinationApi,
    callEnableDisableTouristicDestinationByIdApi,
    callDeleteTouristicDestinationByIdApi,
    callFetchTouristicDestinationByIdApi,
  } = useTouristicDestinationPackage();
  /**
   * Use States
   */
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  const initialDataArr = {
    imageUrl: [],
    imageName: "",
    order: 0,
    altText: 0,
    titleMetaTag: "",
    tagMetaTag: "",
    authorMetaTag: "",
    copyRightMetaTag: "",
    type: "REGULAR",
  };

  const [miniBanner, setMiniBanner] = useState({
    selectedImages: "",
    nomeFile: "",
    altText: "",
    titoloMetaTag: "",
    tagMetaTag: "",
    autoreMetaTag: "",
    copyrightMetaTag: "",
    redirectLink: "",
  });

  const [landingValues, setLandingValues] = useState({
    firstValue: "Assign Contacts",
    secondValue: "Creation Date",
    thirdValue: "Highest Revenue",
  });

  const [eventValues, setEventValues] = useState({
    firstValue: "Assign Contacts",
    secondValue: "Creation Date",
    thirdValue: "Highest Revenue",
  });

  const [touristicValues, setTouristicValues] = useState({
    firstValue: "Assign Contacts",
    secondValue: "Creation Date",
    thirdValue: "Highest Revenue",
  });

  const [landingRows, setlandingRows] = useState([]);

  const [idealVacationRows, setIdealVacationRows] = useState([]);

  const [eventRows, setEventRows] = useState([
    {
      id: 1,
      eventName: "Estate",
      views: "60.234",
      createdBy: "22 September 2023",
      quoteRequests: "25.234",
      order: "1",
      totalRevenue: "24.000,21 €",
      bookings: "135",
    },
    {
      id: 2,
      eventName: "San Valentino",
      views: "55.344",
      createdBy: "29 September 2023",
      quoteRequests: "26.344",
      order: "2",
      totalRevenue: "38.000,59 €",
      bookings: "234",
    },
    {
      id: 3,
      eventName: "Natale",
      views: "54.233",
      createdBy: "24 September 2023",
      quoteRequests: "23.233",
      order: "3",
      totalRevenue: "34.000,21 €",
      bookings: "199",
    },
    {
      id: 4,
      eventName: "Capodanno",
      views: "59.313",
      createdBy: "22 September 2023",
      quoteRequests: "22.313",
      order: "4",
      totalRevenue: "38.000,59 €",
      bookings: "299",
    },
    {
      id: 5,
      eventName: "Pasqua",
      views: "21.120",
      createdBy: "20 September 2023",
      quoteRequests: "21.120",
      order: "5",
      totalRevenue: "34.000,21 €",
      bookings: "193",
    },
  ]);

  const [touristicRows, setTouristicRows] = useState([]);
  const [isReloadLandingTable,setIsReloadLandingTable] = useState(false);

  const navigate = useNavigate();

  const handleLandingChange = (event, field) => {
    setLandingValues((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const handleEventChange = (event, field) => {
    setEventValues((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const handleTouristicChange = (event, field) => {
    setTouristicValues((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const updateMiniBanner = (newData) => {
    const updatedData = { ...newData };
    setMiniBanner(updatedData);
  };

  const initialArticleArr = {
    image: [],
    date: "",
    title: "",
    description: "",
  };

  const [articleArr, setArticleArr] = useState([
    {
      ...initialArticleArr,
      image: article1,
      date: "7 JANUARY 2023",
      title: "Blog title",
      description: "Blow Brief description",
    },
    {
      ...initialArticleArr,
      image: article2,
      date: "8 JANUARY 2023",
      title: "Vita in Trentino",
      description:
        "Il Trentino che ci abita e che abitiamo, custodiamo, immaginiamo. La cura della nostra casa, che è la tua. La consapevolezza",
    },
  ]);

  // const updateArticleArr = (index, newData) => {
  //   const updatedDataArr = [...articleArr];
  //   updatedDataArr[index] = { ...newData };
  //   setArticleArr(updatedDataArr);
  // };

  const classes = useStyles();

  useEffect(()=>{
    if(isReloadLandingTable){
      getAllLandingPages();
    }
  },[isReloadLandingTable])

  /**
   * Functions
   */
  const getAllLandingPages = async () => {
    await callFetchAllLandingPagesApi()
      .then((response) => {
        
        if (response && response?.data && response?.data?.length > 0) {
          const modifiedData = response.data.map((item) => {
            const { bannerImages, assignedHotels, ...rest } = item;

            const hotelsCount = item.assignedHotels
              ? item.assignedHotels.length
              : 0;

            return {
              ...rest,
              assignedHotels : item?.assignedHotels,
              hotelsCount: hotelsCount,
            };
          });
          setIsReloadLandingTable(false);
          setlandingRows(modifiedData);
        }
      })
      .catch((err) => {
        console.error("Error: getting landing pages!", err);
      });
  };

  const enableDisableLandingPage = async (landingPageId, isEnabled) => {
    await callEnableDisableLandingPageByIdApi(landingPageId, isEnabled)
      .then((res) => {
        const updatedRows = landingRows.map((row) => {
          if (row.id === landingPageId) {
            return { ...row, isActive: !row.isActive };
          }
          return row;
        });
        setlandingRows(updatedRows);
      })
      .catch((err) => {
        console.error("Error: callEnableDisableLandingPageByIdApi");
      });
  };

  const deleteLandingPage = async (landingPageId) => {
    await callDeleteLandingPageByIdApi(landingPageId)
      .then((res) => {
        if (res.isSuccess) getAllLandingPages();
        else console.error("Error: deleteLandingPage");
      })
      .catch((err) => {
        console.error("Error: deleteLandingPage");
      });
  };

  /**
   * Ideal Vacations functions
   */
  const getAllidealVacations = async () => {
    await callFetchAllIdealVacationsApi()
      .then((response) => {
        if (response && response?.data && response?.data?.length > 0) {
          setIdealVacationRows(response.data);
        }
      })
      .catch((err) => {
        console.error("Error: getting ideal Vacation!", err);
      });
  };

  const deleteIdeaVacation = async (idealVacationId) => {
    await callDeleteIdealVacationByIdApi(idealVacationId)
      .then((res) => {
        if (res.isSuccess) getAllidealVacations();
        else console.error("Error: delete ideal Vacation");
      })
      .catch((err) => {
        console.error("Error: delete ideal Vacation");
      });
  };

  const onRowClickedOfIdealVacation = (event) => {
    if (event.column.colDef?.headerName !== "")
      window.location.href = `/ideal-vacation/${event.data.id}`;
  };

  /**
   * Event Package
   */
  const getAllEventPackages = async () => {
    await callFetchAllEventPackagesApi()
      .then((response) => {
        if (response && response?.data && response?.data?.length > 0) {
          setEventRows(response?.data);
        }
      })
      .catch((err) => {
        console.error("Error: getting Event Packages!", err);
      });
  };

  const enableDisableEventPackage = async (eventPackageId, isEnabled) => {
    await callEnableDisableEventPackageByIdApi(eventPackageId, isEnabled)
      .then((res) => {
        const updatedRows = eventRows.map((row) => {
          if (row.id === eventPackageId) {
            return { ...row, isActive: !row.isActive };
          }
          return row;
        });
        setEventRows(updatedRows);
      })
      .catch((err) => {
        console.error("Error: callEnableDisableEventPackageByIdApi");
      });
  };

  const deleteEventPackage = async (eventPackageId) => {
    await callDeleteEventPackageByIdApi(eventPackageId)
      .then((res) => {
        if (res.isSuccess) getAllEventPackages();
        else console.error("Error: deleteEventPackage");
      })
      .catch((err) => {
        console.error("Error: deleteEventPackage");
      });
  };

  const onEventPackageRowClicked = (event) => {
    if (event.column.colDef?.headerName !== "Attivo")
      window.location.href = `/event-package/${event.data.id}`;
  };

  /**
   * Turistic Package
   */

const getAllTouristic = async () => {
  await callFetchAllTouristicDestinationApi()
    .then((response) => {
      if (response && response?.data && response?.data?.length > 0) {
        setTouristicRows(response?.data);
      }
    })
    .catch((err) => {
      console.error("Error: get All Touristic!", err);
    });
};

const enableDisableTouristic = async (touristicDId, isEnabled) => {
  await callEnableDisableTouristicDestinationByIdApi(touristicDId, isEnabled)
    .then((res) => {
      const updatedRows = touristicRows.map((row) => {
        if (row.id === touristicDId) {
          return { ...row, isActive: !row.isActive };
        }
        return row;
      });
      setTouristicRows(updatedRows);
    })
    .catch((err) => {
      console.error("Error: callEnableDisableTouristicByIdApi");
    });
};

const deleteTouristic = async (touristicDId) => {
  await callDeleteTouristicDestinationByIdApi(touristicDId)
    .then((res) => {
      if (res.isSuccess) getAllTouristic();
      else console.error("Error: Touristic Destination");
    })
    .catch((err) => {
      console.error("Error: Touristic Destination");
    });
};

    const onTouristicRowClicked = (event) => {
      if (event.column.colDef?.headerName !== "Attivo")
        window.location.href = `/touristic-destination/${event.data.id}`;
    };

  /**
   * Side Effects
   */



  useEffect(() => {
    // Load Landing Pages
    getAllLandingPages();

    // load Ideal vacations
    getAllidealVacations();

    // load Event packages
    getAllEventPackages();

    // load Turistic Destination
    getAllTouristic();
  }, []);

  const onRowClicked = (event) => {
    if (event.column.colDef?.headerName !== "Attivo")
      window.location.href = `/landing-page/${event.data.id}`;
  };

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ padding: "40px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ color: "#26529C", fontSize: "40px", fontWeight: "600" }}
          >
            HoEscape
          </Typography>
        </Box>
        <Divider sx={{ marginTop: "16px" }} />
        <Box sx={{ marginTop: "20px" }}>
          <Typography
            sx={{
              color: "#26529C",
              fontSize: "32px",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            Banners - Home
          </Typography>
          <Card />
        </Box>

        <Box sx={{ marginTop: "36px" }}>
          <Typography
            sx={{
              color: "#26529C",
              fontSize: "32px",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            MiniBanner - Home
          </Typography>
          <MiniBannerCard />
        </Box>

        <Box sx={{ marginTop: "36px" }}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: "600", color: "#26529C" }}
          >
            Landing Page
          </Typography>

          <Box sx={{ marginTop: "30px" }}>
            <Table
              onRowClicked={onRowClicked}
              rows={landingRows}
              enableDisableLandingPage={(landingPageId, isEnabled) => {
                enableDisableLandingPage(landingPageId, isEnabled);
              }}
              deleteLandingPage={(landingPageId) =>
                deleteLandingPage(landingPageId)
              }
              rightBtnTxt={"Aggiungi Landing Page"}
              rightBtnClick={() => navigate("/landing-page")}
              setIsReload={setIsReloadLandingTable}
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "92px" }}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: "600", color: "#26529C" }}
          >
            Ideal Vacations
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "34px",
            }}
          ></Box>
          <Box sx={{ marginTop: "30px" }}>
            <IdealVacationsTable
              rows={idealVacationRows}
              onRowClicked={onRowClickedOfIdealVacation}
              deleteIdealVacation={(idealVacationId) =>
                deleteIdeaVacation(idealVacationId)
              }
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "92px" }}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: "600", color: "#26529C" }}
          >
            Event Package
          </Typography>

          <Box sx={{ marginTop: "30px" }}>
            <EventPackageTable
              onRowClicked={onEventPackageRowClicked}
              rows={eventRows}
              enableDisable={(eventPackageId, isEnabled) => {
                enableDisableEventPackage(eventPackageId, isEnabled);
              }}
              deleteRecord={(eventPackageId) =>
                deleteEventPackage(eventPackageId)
              }
              rightBtnTxt={"Aggiungi Event Package"}
              rightBtnClick={() => navigate("/event-package")}
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "92px" }}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: "600", color: "#26529C" }}
          >
            Touristic Destination
          </Typography>

          <Box sx={{ marginTop: "30px" }}>
            <TouristicDestinationTable
              onRowClicked={onTouristicRowClicked}
              rows={touristicRows}
              enableDisable={(touristicDId, isEnabled) => {
                enableDisableTouristic(touristicDId, isEnabled);
                }}
                deleteRecord={(touristicDId) =>
                  deleteTouristic(touristicDId)
              }
              rightBtnTxt={"Aggiungi Touristic Destination"}
              rightBtnClick={() => navigate("/touristic-destination")}
              
            />
            {/* <Table
              rows={touristicRows}
              rightBtnTxt={"Aggiungi Touristic Destination"}
              rightBtnClick={() => navigate("/touristic-destination")}
            /> */}
          </Box>
        </Box>

        <Box sx={{ marginTop: "92px" }}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: "600", color: "#26529C" }}
          >
            Blog Highlighted - Home
          </Typography>
          <BlogCard />
        </Box>
      </Box>
    </Layout>
  );
}
