import axiosConfig from "../axiosConfig";

class HotelDashboardService {
  fetchHotelImages = async (hotelCode) => {
    if (hotelCode) {
      return await axiosConfig.get(`/Dashboard/Hotels/${hotelCode}/images`);
    }
    console.warn(
      "hotelCode not specified, HotelDashBoardService.fetchHotelImages"
    );

    return null;
  };
  fetchHotelByCode = async (hotelCode) => {
    if (hotelCode) {
      return await axiosConfig.get(`/Dashboard/Hotel/${hotelCode}`);
    }
    console.warn(
      "hotelCode not specified, HotelDashBoardService.fetchHotelByCode"
    );

    return null;
  };
  fetchAllHotel = async (body) => {
    return await axiosConfig.get(`/Dashboard/Hotels`, {
      params: body,
    });
  };
  fetchAllHotelByFilter = async (body) => {
    return await axiosConfig.get(`/Hotel/getHotels/${body}`);
  };
  deleteHotel = async (hotelCode,id) => {
    return await axiosConfig.delete(`/Dashboard/Hotel/delete?hotelCode=${encodeURIComponent(hotelCode)}&id=${encodeURIComponent(id)}`);
  };
  editHotelDetails = async (body) => {
    return await axiosConfig.post(`/Dashboard/Hotel/Update`, body);
  };
  fetchHotelRooms = async (hotelCode) => {
    if (hotelCode) {
      return await axiosConfig.get(`/Dashboard/Hotels/${hotelCode}/rooms`);
    }
    console.warn(
      "hotelCode not specified, HotelDashBoardService.fetchHotelRooms"
    );

    return null;
  };

  uploadBanner = async (body) => {
    if (body) {
      return await axiosConfig.post(`Dashboard/upload-banner-image`, body);
    }
    console.warn("body not specified, HotelDashBoardService.uploadBanner");

    return null;
  };

  updateBanner = async (body) => {
    if (body) {
      return await axiosConfig.post(`Dashboard/banner-images/update`, body);
    }
    console.warn("body not specified, HotelDashBoardService.updateBanner");

    return null;
  };

  getBannerImages = async (body) => {
    if (body) {
      return await axiosConfig.get(`Dashboard/banner-images`, {
        params: body,
      });
    }
    console.warn("body not specified, HotelDashBoardService.getBannerImages");

    return null;
  };

  deteleBannerImages = async (bannerImageId) => {
    if (bannerImageId) {
      return await axiosConfig.delete(
        `Dashboard/banner-images/${bannerImageId}/delete`
      );
    }
    console.warn(
      "bannerImageId not specified, HotelDashBoardService.deteleBannerImages"
    );

    return null;
  };

  updateOrder = async (body) => {
    if (body) {
      return await axiosConfig.post(
        `Dashboard/banner-images/update-orders`,
        body
      );
    }
    console.warn("body not specified, HotelDashBoardService.updateOrder");

    return null;
  };

  getBlogs = async () => {
    return await axiosConfig.get("Dashboard/blogs");
  };

  uploadBlog = async (body) => {
    if (body) {
      return await axiosConfig.post(`Dashboard/blog`, body);
    }
    console.warn("body not specified, HotelDashBoardService.uploadBlog");

    return null;
  };

  updateBlog = async (body) => {
    if (body) {
      return await axiosConfig.post(`Dashboard/blog/update`, body);
    }
    console.warn("body not specified, HotelDashBoardService.updateBlog");

    return null;
  };

  deteleBlog = async (blogId) => {
    if (blogId) {
      return await axiosConfig.delete(`Dashboard/blogs/${blogId}/delete`);
    }
    console.warn("blogId not specified, HotelDashBoardService.deteleBlog");

    return null;
  };

  uploadHotelImage = async (body) => {
    if (body) {
      return await axiosConfig.post(`Dashboard/upload-hotel-image`, body);
    }
    console.warn("body not specified, HotelDashBoardService.uploadHotelImage");

    return null;
  };
  hotelDatacleanCatch = async () => {
    return await axiosConfig.get("Dashboard/Hotel/FlushData");
  };

  getAllTransport = async () => {
    return await axiosConfig.get("Dashboard/getAllTransport");
  };
}

export default new HotelDashboardService();
