import { useCallback } from "react";
import { useHotelConfigurationContext } from "../../store/slices/HotelConfigurationSlicer";
import HotelDashboardService from "../../services/API/hotelDashboard";
import { FetchHotelImages } from "../../store/reducers/HotelDashboardConfigurationReducer";
import { FetchHotelDetails } from "../../store/reducers/HotelDetailsConfigurationReducer";
import { FetchAllHotels } from "../../store/reducers/HotelsDashboarReducer";
import { HotelsDashboarFilter } from "../../store/reducers/HotelsDashboarFilterReducer";
import { useHotelsDashboardContext } from "../../store/slices/HotelsDashboardSlicer";
import { useHotelsDashboardFilterContext } from "../../store/slices/HotelsDashboardFilterSlicer";
import { useHotelDetailsConfigurationContext } from "../../store/slices/HotelDetailsConfigurationSlicer";

const useHotelDashboard = () => {
  const { dispatch: fetchHotelImagesDispatch } = useHotelConfigurationContext();
  const { dispatch: fetchAllHotelsDispatch } = useHotelsDashboardContext();
  const { dispatch: fetchAllHotelsFilterDispatch } = useHotelsDashboardFilterContext();
  const { dispatch: fetchHotelDetailsDispatch } =
    useHotelDetailsConfigurationContext();

  const callFetchHotelImagesApi = useCallback(
    async (hotelCode) => {
      await HotelDashboardService.fetchHotelImages(hotelCode).then(
        (response) => {
          fetchHotelImagesDispatch(FetchHotelImages(response));
        }
      );
    },
    [fetchHotelImagesDispatch]
  );

  const callFetchHotelDetailsApi = useCallback(
    async (hotelCode) => {
      await HotelDashboardService.fetchHotelByCode(hotelCode).then(
        (response) => {
          fetchHotelDetailsDispatch(FetchHotelDetails(response));
        }
      );
    },
    [fetchHotelDetailsDispatch]
  );

  const callFetchAllHotelsApi = useCallback(
    async (body) => {
      await HotelDashboardService.fetchAllHotel(body).then((response) => {
        fetchAllHotelsDispatch(FetchAllHotels(response));
      });
    },
    [fetchAllHotelsDispatch]
  );

  const callFetchAllHotelsByFilterApi = useCallback(
    async (body) => {
      await HotelDashboardService.fetchAllHotelByFilter(body).then((response) => {
        fetchAllHotelsFilterDispatch(HotelsDashboarFilter(response));
      });
    },
    [fetchAllHotelsFilterDispatch]
  );

  const callUpdateHotelApi = useCallback(async (body) => {
    await HotelDashboardService.editHotelDetails({
      hotelDetailsExtension: body,
    }).then((response) => {
      return response;
    });
  }, []);

  const callCleanHotelApi = useCallback(async () => {
    await HotelDashboardService.hotelDatacleanCatch().then((response) => {
      return response;
    });
  }, []);

  const callAllTransportApi = useCallback(async () => {
    await HotelDashboardService.getAllTransport().then((response) => {
      return response;
    });
  }, []);


  const deleteHotelApi = useCallback(async (hotelCode,id) => {
    await HotelDashboardService.deleteHotel(hotelCode,id).then((response) => {
      return response;
    });
  }, []);

  const dispatchFetchHotelImages = useCallback(
    async (hotelCode) => {
      await callFetchHotelImagesApi(hotelCode);
    },
    [callFetchHotelImagesApi]
  );

  const dispatchCleanHotelData = useCallback(
    async () => {
      await callCleanHotelApi();
    },
    [callFetchHotelImagesApi]
  );

  const dispatchFetchHotelDetails = useCallback(
    async (hotelCode) => {
      await callFetchHotelDetailsApi(hotelCode);
    },
    [callFetchHotelDetailsApi]
  );

  const dispatchFetchAllHotels = useCallback(
    async (body) => {
      await callFetchAllHotelsApi(body);
    },
    [callFetchAllHotelsApi]
  );

  const dispatchFetchAllHotelsByFilter = useCallback(
    async (body) => {
      await callFetchAllHotelsByFilterApi(body);
    },
    [callFetchAllHotelsByFilterApi]
  );

  const dispatchAllTransportData = useCallback(
    async () => {
      await callAllTransportApi();
    },
    [callFetchHotelImagesApi]
  );

  const callUploadBannerApi = useCallback(async (body) => {
    const formData = new FormData();
    formData.append("File", body?.bannerImage?.imageUrl);
    formData.append("ImageName", body?.bannerImage?.imageName);
    formData.append("Order", 0);
    formData.append("AltText", body?.bannerImage?.altText);
    formData.append("TitleMetaTag", body?.bannerImage?.titleMetaTag);
    formData.append("TagMetaTag", body?.bannerImage?.tagMetaTag);
    formData.append("AuthorMetaTag", body?.bannerImage?.authorMetaTag);
    formData.append("CopyRightMetaTag", body?.bannerImage?.copyRightMetaTag);
    formData.append("Type", body?.bannerImage?.type);
    formData.append("RedirectUrl", body?.bannerImage?.redirectUrl);

    await HotelDashboardService.uploadBanner(formData).then((response) => {
      return response;
    });
  }, []);

  const dispatchBanner = useCallback(
    async (body) => {
      await callUploadBannerApi(body);
    },
    [callUploadBannerApi]
  );

  const callUpdateBannerApi = useCallback(async (body) => {
    const formData = new FormData();
    formData.append("id", body?.updateBannerImage?.id);
    formData.append("File", body?.updateBannerImage?.imageUrl);
    formData.append("ImageName", body?.updateBannerImage?.imageName);
    formData.append(
      "IsImageUpdate",
      typeof body?.updateBannerImage?.imageUrl === "string" ? false : true
    );
    formData.append("Order", 0);
    formData.append("AltText", body?.updateBannerImage?.altText);
    formData.append("TitleMetaTag", body?.updateBannerImage?.titleMetaTag);
    formData.append("TagMetaTag", body?.updateBannerImage?.tagMetaTag);
    formData.append("AuthorMetaTag", body?.updateBannerImage?.authorMetaTag);
    formData.append(
      "CopyRightMetaTag",
      body?.updateBannerImage?.copyRightMetaTag
    );
    formData.append("Type", body?.updateBannerImage?.type);
    formData.append("RedirectUrl", body?.updateBannerImage?.redirectUrl);

    await HotelDashboardService.updateBanner(formData).then((response) => {
      return response;
    });
  }, []);

  const dispatchUpdateBanner = useCallback(
    async (body) => {
      return await callUpdateBannerApi(body);
    },
    [callUpdateBannerApi]
  );

  const callUpdateOrderApi = useCallback(async (body) => {
    await HotelDashboardService.updateOrder(body).then((response) => {
      return response;
    });
  }, []);

  const dispatchUpdateOrder = useCallback(
    async (body) => {
      return await callUpdateOrderApi(body);
    },
    [callUpdateOrderApi]
  );

  const callUploadBlogApi = useCallback(async (body) => {
    const formData = new FormData();
    formData.append("File", body?.imageUrl);
    formData.append(
      "IsImageUpdate",
      typeof body?.imageUrl === "string" ? false : true
    );
    formData.append("ArticleName", body?.articleName);
    formData.append("Description", body?.description);
    formData.append("RedirectUrl", body?.redirectUrl);

    await HotelDashboardService.uploadBlog(formData).then((response) => {
      return response;
    });
  }, []);

  const dispatchBlog = useCallback(
    async (body) => {
      await callUploadBlogApi(body);
    },
    [callUploadBlogApi]
  );

  const callUpdateBlogApi = useCallback(async (body) => {
    const formData = new FormData();
    formData.append("id", body?.id);
    formData.append("File", body?.imageUrl);
    formData.append(
      "IsImageUpdate",
      typeof body?.imageUrl === "string" ? false : true
    );
    formData.append("ArticleName", body?.articleName);
    formData.append("Description", body?.description);
    formData.append("RedirectUrl", body?.redirectUrl);

    await HotelDashboardService.updateBlog(formData).then((response) => {
      return response;
    });
  }, []);

  const dispatchUpdateBlog = useCallback(
    async (body) => {
      await callUpdateBlogApi(body);
    },
    [callUpdateBlogApi]
  );

  return {
    dispatchFetchHotelImages,
    dispatchFetchAllHotels,
    dispatchFetchAllHotelsByFilter,
    dispatchFetchHotelDetails,
    callUpdateHotelApi,
    dispatchBanner,
    dispatchUpdateBanner,
    dispatchUpdateOrder,
    dispatchBlog,
    dispatchUpdateBlog,
    deleteHotelApi,
    dispatchCleanHotelData,
    dispatchAllTransportData
  };
};

export default useHotelDashboard;
