import { Box, Button, Typography,Dialog,DialogActions, DialogContent, DialogContentText,DialogTitle,TextField } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useState } from "react";
import CustomFilter from "../CustomFilter/CustomFilter";
import useLandingPage from "../../library/hooks/useLandingPage/useLandingPage";

import { useNavigate } from "react-router-dom/dist";
export default function Table({
  rows,
  enableDisableLandingPage,
  deleteLandingPage,
  onRowClicked,
  rightBtnTxt,
  rightBtnClick,
  setIsReload
}) {
  const [open, setOpen] = useState(false);
  const [landingName, setLandingName] = useState("");
  const [landingURL, setLandingURL] = useState("");
  const [duplicateData,setDuplicateData] = useState(null);
  const [formInputs, setFormInputs] = useState({
    id: "-",
    pageURL: "",
    pageTitle: "",
    pageH1: null,
    pageH2: null,
    pageMarkdown: null,
    bannerImages: [],
    initialSearchQuery: null,
    initialSearchStartDate: null,
    initialSearchEndDate: null,
    assignedHotels: [],
    isActive: true,
    createdBy: "-",
    createdDate: new Date().toISOString(),
    tempImage: null,
    selectedImageFileChanged: false,
    pageMarkdown2:""
  });

  const {
    callFetchLandingPageByIdApi,
    callCreateLandingPageApi,
  } = useLandingPage();

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      // filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const [columnVisibility, setColumnVisibility] = useState({
    PageTitle: false,
    PageURL: false,
    HotelsCount: false,
    CreatedBy: false,
    CreatedDate: false,
    Views: false,
    QuoteRequests: false,
    Bookings: false,
    TotalRevenue: false,
    NumeroRichiestePreventivi: false,
    NumeroDiPrenotazioni: false,
    RatioPrenotazioniSuPreventivi: false,
    NumeroDiContattiGenerati: false,
    NumeroDiNegoziazioniGenerate: false,
    TotaleRichiesto: false,
    TotaleGenerato: false,
    UltimaPrenotazione: false,
  });

  /**
   * functions
   */

  const enableDisableLandingPageLocal = (e, params) => {
    const confirmed = window.confirm(
      `Do you want to disable the ${params.node.data.pageTitle}?`
    );

    if (confirmed) {
      e.target.checked = !params.node.data.isActive;

      enableDisableLandingPage(params.node.data.id, !params.node.data.isActive);
    } else {
      e.target.checked = params.node.data.isActive;
    }
  };

  const deleteLandingPageLocal = (e, params) => {
    const confirmed = window.confirm(
      `Do you want to delete the ${params.node.data.pageTitle}?`
    );

    if (confirmed) {
      deleteLandingPage(params.node.data.id);
    }
  };

  const dublicateLandingPageLocal = (e, params) => {
    if(rows){
      
      setDuplicateData(params.data);
      setOpen(true);
    }
  };

  const handleSubmitByLandingPage = async () =>{

    if(!landingURL && !landingName){
      alert("Enter name and URL")
      return;
    }
    let locationModel = null;
    if(duplicateData?.location){
      locationModel = JSON.stringify(duplicateData?.location);
  }

    let tempObject = {
      id: "-",
      pageURL: landingURL,
      pageTitle: landingName,
      pageH1: duplicateData?.pageH1 ?? "",
      pageH2: duplicateData?.pageH2 ?? "",
      pageMarkdown: duplicateData?.pageMarkdown ?? "",
      bannerImages: [],
      initialSearchQuery: null,
      initialSearchStartDate: duplicateData?.initialSearchStartDate ?? "" ,
      initialSearchEndDate: duplicateData?.initialSearchEndDate ?? "",
      isActive: true,
      createdBy: "-",
      createdDate: new Date().toISOString(),
      tempImage: null,
      selectedImageFileChanged: false,
      pageMarkdown2:duplicateData?.pageMarkdown2,
      startDate:duplicateData?.startDate,
      endDate:duplicateData?.endDate,
      IsSearchable:duplicateData?.isSearchable,
      locationStr: locationModel,
      pageMarkdown2 :duplicateData?.PageMarkdown2 ?? "",
      assignedHotels:duplicateData?.assignedHotels
    };

    await callCreateLandingPageApi(tempObject, null)
        .then((res) => {
          if (res.isSuccess) {
            setIsReload(true);
            setLandingName("");
            setLandingURL("");
            setDuplicateData(null);
            setOpen(false);
          } else {
            console.error("Error: Create Landing page", res.message);
          }
        })
        .catch((err) => {
          console.error("Error: Create Landing page", err);
        });
  }

  const handleClose = () => {
    setOpen(false);
    setLandingName("");
    setLandingURL("");
    setDuplicateData(null);
  };

  const handleColumnVisibilityChange = (event) => {
    setColumnVisibility({
      ...columnVisibility,
      [event.target.name]: event.target.checked,
    });
  };

  const handleLandingName = (event)=>{
    setLandingName(event.target.value);
  }
  
  const handleLandingURL = (event)=>{
    setLandingURL(event.target.value);
  }

  const columns = [
    {
      // field: "Copertina",ssss
      headerName: "Attivo",
      maxWidth: 80,
      suppressMenu: true,
      cellRenderer: function (params) {
        return (
          <div style={{ display: "flex", marginTop: "10px" }}>
            <input
              type="checkbox"
              checked={params.data.isActive}
              onChange={(e) => enableDisableLandingPageLocal(e, params)}
            />
            <img
              src="/static/media/delete.5756e7189ef69e97a5d76639b98b91ee.svg"
              alt="img"
              onClick={(e) => deleteLandingPageLocal(e, params)}
            ></img>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZElEQVR4nOXUWwrAIAxE0bu8Sve/Ae0+xv9C+ggxaDuQL4kHExC+HDmrAWUkIKBmvDwf2IEjYOaygBY0c1mAd3Y69c0J6MXZjwDd1PzA+jtIBa7yuG840II+OzPFiVRgs69dPR1cjsKRt7mLFwAAAABJRU5ErkJggg==" 
            alt="duplicate"
            onClick={(e) => dublicateLandingPageLocal(e, params)}
            ></img>
            {/* <img
              src="/static/media/delete.5756e7189ef69e97a5d76639b98b91ee.svg"
              alt="img"
              onClick={(e) => dublicateLandingPageLocal(e, params)}
            ></img> */}
          </div>
        );
      },
    },

    {
      field: "pageTitle",
      headerName: "Page Title",
      minWidth: 150,
      hide: columnVisibility.PageTitle,
    },
    {
      field: "pageURL",
      headerName: "URL",
      minWidth: 150,
      hide: columnVisibility.PageURL,
      filter: "agNumberColumnFilter",
    },
    {
      field: "hotelsCount",
      headerName: "Number Hotels",
      width: 150,
      hide: columnVisibility.HotelsCount,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      minWidth: 150,
      hide: columnVisibility.CreatedBy,
    },
    {
      field: "createdDate",
      headerName: "Created When",
      minWidth: 150,
      hide: columnVisibility.CreatedDate,
    },
    {
      field: "",
      headerName: "Views",
      minWidth: 200,
      hide: columnVisibility.Views,
    },
    {
      field: "",
      headerName: "Quote Requests",
      width: 100,
      hide: columnVisibility.QuoteRequests,
    },
    //-----------------------------------
    {
      field: "",
      headerName: "Bookings",
      width: 200,
      hide: columnVisibility.Bookings,
    },
    {
      field: "",
      headerName: "Total Revenue",
      width: 200,
      hide: columnVisibility.TotalRevenue,
    },
    {
      field: "",
      headerName: "Numero Richieste Preventivi",
      width: 200,
      hide: columnVisibility.NumeroRichiestePreventivi,
    },
    {
      field: "",
      headerName: "Numero di Prenotazioni",
      width: 250,
      hide: columnVisibility.NumeroDiPrenotazioni,
    },
    {
      field: "",
      headerName: "Ratio Prenotazioni su Preventivi",
      width: 250,
      hide: columnVisibility.RatioPrenotazioniSuPreventivi,
    },
    {
      field: "",
      headerName: "Numero di Contatti Generati",
      width: 300,
      hide: columnVisibility.NumeroDiContattiGenerati,
    },
    {
      field: "",
      headerName: "Numero di Negoziazioni Generate",
      width: 250,
      hide: columnVisibility.NumeroDiNegoziazioniGenerate,
    },
    {
      field: "",
      headerName: "Totale Richiesto",
      width: 300,
      hide: columnVisibility.TotaleRichiesto,
    },
    {
      field: "",
      headerName: "Totale Generato",
      width: 200,
      hide: columnVisibility.TotaleGenerato,
    },
    {
      field: "",
      headerName: "Ultima Prenotazione",
      width: 200,
      hide: columnVisibility.UltimaPrenotazione,
    },
  ];

  const hideColumns = [
    "EventName",
    "CreatedBy",
    "Views",
    "QuoteRequests",
    "Bookings",
    "TotalRevenue",
    "Order",
    "url",
    "numberHotels",
    "pageTitle",
    "numeroRichiestePreventivi",
    "numeroDiPrenotazioni",
    "ratioPrenotazioniSuPreventivi",
    "numeroDiContattiGenerati",
    "numeroDiNegoziazioniGenerate",
    "totaleRichiesto",
    "totaleGenerato",
    "ultimaPrenotazione",
  ];

  const navigate = useNavigate();

  return (
    <div className="ag-theme-quartz" style={{ height: 350 }}>
      <Box
        sx={{
          margin: "30px 0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomFilter
          hideColumns={hideColumns}
          columnVisibility={columnVisibility}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
        />
        <Box className="text-[20px] font-semibold text-primary placeholder:text-primary border-[1.5px] border-primary rounded-[5px] px-[10px] h-[42px] bg-primary bg-opacity-[0.13]">
          <Button onClick={rightBtnClick}>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#26529C",
                fontWeight: "600",
                textTransform: "none",
              }}
            >
              {rightBtnTxt}
            </Typography>
          </Button>
        </Box>
      </Box>
      <AgGridReact
        onCellClicked={onRowClicked}
        rowData={rows}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        includeHiddenColumnsInQuickFilter={true}
        pagination={true}
        paginationPageSize={10}
      />


    <Dialog open={open} onClose={handleClose} sx={{ margin: "100px" }}>
        <DialogTitle>Add Duplicate</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter Landing Name:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="landingName"
            label="Name"
            type="text"
            fullWidth
            value={landingName}
            onChange={handleLandingName}
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText>Enter URL:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="URL"
            label="URL"
            type="text"
            fullWidth
            value={landingURL}
            onChange={handleLandingURL}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ width: "150px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ width: "150px" }}
            onClick={handleSubmitByLandingPage}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    
  );
}
